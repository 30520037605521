import {FC, Fragment, useEffect, useState} from "react";
import {Client} from "../entities/Client";
import api from "../api";
import {state} from "../store";
import {ActionIcon, Badge, Button, Card, Grid, Group, Indicator, Modal} from "@mantine/core";
import {IconDoor, IconTrash} from "@tabler/icons-react";
import ClientForm from "../forms/ClientForm";
import {defaultSearchResult} from "../entities/SearchResult";
import {useNavigate} from "react-router-dom";

const ClientPage: FC<{ids: string}> = props => {
    const {ids} = props
    const idsAsNumberArray = ids.split(':').map(it => Number(it))
    const navigate = useNavigate()
    const [clients, setClients] = useState<Client[]>([])
    const [edit, setEdit] = useState<Client|undefined>()
    const handleOk = async (client: Client) => {
        try {
            const savedClient = await api.save<Client>('client', client)
            setClients(clients.map(it => savedClient.id === it.id ? savedClient : it))
        } catch (e: any) {
            state.notifyError(e.message)
        }
        setEdit(undefined)
    }
    const handleDelete = async (client: Client) => {
        if (window.confirm(`Are you sure to delete client: ${client.fullName} ?`)){
            try {
                if (await api.deleteIds('client', [client.id])){
                    setClients(clients.filter(it => it.id !== client.id))
                }
            } catch (e: any) {
                state.notifyError(e.message)
            }
        }
    }
    const handleGotoGates = (client: Client) => {
        state.updateSearchResult({...defaultSearchResult, gateIds: client.gateIds})
        navigate('/app/search-result/gate')
    }

    useEffect(() => {
        (async () => {
            try {
                setClients((await api.entities<Client>('client', idsAsNumberArray)).sort((it1, it2) => idsAsNumberArray.indexOf(it1.id) - idsAsNumberArray.indexOf(it2.id)))
            } catch (e: any){
                state.notifyError(e.message)
            }
        })()
    }, [ids]);
    return (
        <Fragment>
            {clients.map(it => <Grid.Col key={it.id} span={{ base: 12, md: 6, lg: 3 }}>
                <Card shadow="md">
                    <Card.Section>
                        <Button variant="transparent" onClick={() => setEdit(it)}>{it.fullName}</Button>
                        {it.native && <Badge>Native</Badge>}
                        {it.public && <Badge>Public</Badge>}
                        {it.enforceProofKeyOfCodeExchange && <Badge>PKCE</Badge>}
                        {it.ignorePasswords && <Badge bg="red">Open</Badge>}
                    </Card.Section>
                    <Card.Section>
                        <Group justify="space-between" w="100%">
                            <ActionIcon variant="transparent" onClick={() => handleGotoGates(it)}>
                                <Indicator size={16} label={String(it.gateIds.length)}>
                                    <IconDoor size={16}/>
                                </Indicator>
                            </ActionIcon>
                            {it.gateIds.length === 0 && <ActionIcon variant="transparent" onClick={() => handleDelete(it)}>
                                <IconTrash size={16}/>
                            </ActionIcon>}
                        </Group>
                    </Card.Section>
                </Card>
            </Grid.Col> )}
            <Modal opened={Boolean(edit)} onClose={() => setEdit(undefined)} title={`Client: ${edit && edit.fullName}`}>
                <ClientForm client={edit} onCancel={() => setEdit(undefined)} onOk={handleOk}/>
            </Modal>
        </Fragment>
    )
}

export default ClientPage

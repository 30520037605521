import { v4 as uuidv4 } from 'uuid'

export interface Client {
  id: 0,
  identifier: string,
  fullName: string,
  public: boolean,
  native: boolean,
  accessible: boolean,
  ignorePasswords: boolean,
  availableRoles: string[],
  availableFeatures: string[],
  availableAspects: string[],
  authorizationCodeLifetimeInSeconds: number,
  accessTokenLifetimeInSeconds: number,
  refreshTokenLifetimeInSeconds: number,
  idTokenLifetimeInSeconds: number,
  secret?: string,
  allowedRedirectUris?: string,
  enforceProofKeyOfCodeExchange: boolean,
  twoFactorAuthentication?: string,
  gateIds: number[],
}

export const defaultClient: Client = {
  id: 0,
  identifier: uuidv4(),
  fullName: '',
  public: false,
  native: false,
  accessible: false,
  ignorePasswords: false,
  availableRoles: [],
  availableFeatures: [],
  availableAspects: [],
  authorizationCodeLifetimeInSeconds: 45,
  accessTokenLifetimeInSeconds: 86400,
  refreshTokenLifetimeInSeconds: 86400,
  idTokenLifetimeInSeconds: 86400,
  enforceProofKeyOfCodeExchange: false,
  gateIds: [],
}
